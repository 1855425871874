import React from "react"
import CreateLesson from "../components/Lessons/CreateLesson";

const CreateLessonPage = () => {
  if (typeof window === "undefined") return null

  return <CreateLesson />
}

export default CreateLessonPage
